<template>
    <div class="content">
        <div class="select">
            <div class="select-box">
                <div
                    class="sb-list"
                    :class="$route.path == item.path ? 'active' : ''"
                    @click="open(item)"
                    v-for="(item, i) in nav"
                    :key="i"
                >
                    <img
                        :src="
                            $route.path == item.path
                                ? item.select_img
                                : item.img
                        "
                        alt=""
                    />
                    <p>{{ item.name }}</p>
                </div>
            </div>
        </div>
        <div class="user-body">
            <!-- <div class="user_info">
                {{ userInfo.account }}
            </div> -->
            <div class="user_main">
                <router-view></router-view>
            </div>
        </div>
        <!-- <informationData /> -->
    </div>
</template>

<script>
// import informationData from "../../components/user/information-data.vue";
import { platformTransfer } from "@/api/user";
export default {
    components: {
        // informationData,
    },
    data() {
        return {
            nav: [
                {
                    name: "基本信息",
                    path: "/home/user/index",
                    img: require("@/static/images/user/13.png"),
                    select_img: require("@/static/images/user/4.png"),
                    isAlert: false,
                },
                {
                    name: "充值记录",
                    path: "/home/user/order",
                    img: require("@/static/images/user/7.png"),
                    select_img: require("@/static/images/user/11.png"),
                    isAlert: false,
                },
                {
                    name: "消费明细",
                    path: "/home/user/consumption",
                    img: require("@/static/images/user/8.png"),
                    select_img: require("@/static/images/user/5.png"),
                    isAlert: false,
                },
                // {
                //     name: "充值",
                //     isAlert: true,
                //     img: require("@/static/images/user/8.png"),
                //     select_img: require("@/static/images/user/5.png"),
                // },
                // {
                //     name: "Api Keys",
                //     path: "/home/user/tokens",
                //     img: require("@/static/images/user/9.png"),
                //     select_img: require("@/static/images/user/12.png"),
                //     isAlert: false,
                // },
                {
                    name: "安全中心",
                    path: "/home/user/password",
                    img: require("@/static/images/user/9.png"),
                    select_img: require("@/static/images/user/12.png"),
                    isAlert: false,
                },
            ],
            userInfo: this.$store.state.user,
        };
    },
    mounted() {
        // console.log(this.$route.path);
    },
    methods: {
        open(item) {
            console.log(item);
            if (item.isAlert) {
                this.$prompt("请输入充值码", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(({ value }) => {
                    platformTransfer({ key: value }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                        }
                    });
                });
            } else {
                this.$router.push(item.path);
            }
        },
    },
    created() {},
};
</script>

<style lang="less" scoped>
.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .user-body {
        flex: 1;
        height: 0;
        overflow: hidden;
        display: flex;
        .user_info {
            flex: 0 0 300px;
            margin-right: 20px;
            background-color: #fff;
        }
        .user_main {
            flex: 1;
            background-color: #fff;
        }
    }
}
.select {
    width: 100%;
    height: auto;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 20px;
    color: #837f7f;
    &-box {
        width: 100%;
        margin: auto;
        display: flex;

        .sb-list {
            width: 140px;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
                margin-bottom: 6px;
            }
        }

        .active {
            color: #349ffd;
            background: linear-gradient(
                180deg,
                #ffffff 0%,
                rgba(118, 191, 255, 0.2)
            );
            border-radius: 20px;
            p {
                color: #349ffd;
            }
        }
    }
}
</style>